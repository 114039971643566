/*
export const orgs = {
    palcareops: {
        logo: 'https://s3.ap-southeast-2.amazonaws.com/cdn.pal.care/organisations/logos/c7283635-5845-4863-8622-5c7ac07d700a.jpg',
        disclaimer: '<strong>Please Note:</strong> Access to client clinical details is restricted to those clients for whom you are assisting in the delivery of health care. Access for other reasons is strictly forbidden. All access to client records are logged.'
    },
    epc: {
        logo: 'https://s3.ap-southeast-2.amazonaws.com/cdn.pal.care/organisations/logos/7a7c94b3-24ed-4754-85a0-997c2a3c7db4.jpg',
        disclaimer: '<strong>Please Note:</strong> Access to client clinical details is restricted to those clients for whom you are assisting in the delivery of health care. Access for other reasons is strictly forbidden. All access to client records are logged.'
    }
}
*/

export const errorLevels = {
    normal: 5,
    extended: 10,
    usererror: 15,
    error: 20
}