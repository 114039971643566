export const getValidSecureSendEntryPoint = /* GraphQL */ `
    query GetSecureSendEntryPoints($tag: String!) {
        getSecureSendEntryPoints(tag: $tag) {
            org
            pref
            ssrequest_ids
        }
    }
`;

export const listValidSecureSendEntryPoints = /* GraphQL */ `
    query ListSecureSendEntryPoints(
        $tag: String
        $filter: ModelSecureSendEntryPointsFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSecureSendEntryPoints(
            tag: $tag
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                org
                upload
                ssrequest_ids
            }
            nextToken
        }
    }
`;

export const getSecureSendRequestFirstLogin = `
    query GetSecureSendRequests($ssrequest_id: String!) {
        getSecureSendRequests(
            ssrequest_id: $ssrequest_id
        ) {
            firstLogin
            org
        }
    }
`;

export const getSecureSendRequestFileViews = `
    query GetSecureSendRequestFileViews($ssrequest_id: String!, $ordinality: Int!) {
        getSecureSendRequestFiles(
            ssrequest_id: $ssrequest_id
            ordinality: $ordinality
        ) {
            views
            maxViews
            firstViewedAt
        }
    }
`;

export const getPalCareOrganisations = /* GraphQL */ `
  query GetPalCareOrganisations($org: String!) {
    getPalCareOrganisations(org: $org) {
      org
      name
      logo
      disclaimerText
      disclaimerAckLabel
      defaultCountryCode
    }
  }
`;