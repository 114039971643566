import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const maxTries = 3;

class AuthConfirmationCode extends Component {
  constructor(props) {
    super(props);

    this.confirmationCodeInput = null;

    this.state = {
      cognitoUser: this.props.currState.cognitoUser,
      confirmationCode: this.props.currState.confirmationCode,
      confirmationCodeErrorMessage: '',
      numTries: 0,
      exhausted: false,
      loading: false
    };
  }

  returnToLogin = (e) => {
    this.props.stateCallback({
      formType: 'signIn'
    });
  };

  onChangeConfirmationCode = (e) => {
    e.persist();
    this.setState({
      confirmationCode: e.target.value
    });
  };

  async isAuthenticated() {
    try {
      //var hasCurrentSession = await Auth.currentSession();
      await Auth.currentSession();
      return true;
    } catch {
      return false;
    }
  }

  handleConfirmationCodeError = (e) => {
    this.props.confirmationCodeError(e);
    if (!e.exhausted) {
      this.setState({
        confirmationCode: ''
      });
      this.confirmationCodeInput.value = '';
      this.confirmationCodeInput.focus();
    }
  };

  async submitConfirmationCode(theState, e) {
    this.setState({
      loading: true
    });

    //console.log('^^ START submitConfirmationCode ^^');

    //console.log('Confirmation Code Submitted: ' + theState.confirmationCode);
    try {
      // eslint-disable-next-line
      var challengeResp = await Auth.sendCustomChallengeAnswer(
        theState.cognitoUser,
        theState.confirmationCode
      );
      //console.log('challengeResp', challengeResp);

      var isReqAuthenticated = await this.isAuthenticated();
      //console.log('isReqAuthenticated', isReqAuthenticated);

      if (isReqAuthenticated) {
        //console.log('isReqAuthenticated true');
        this.props.confirmationCodeSuccess();
      } else {
        //console.log('isReqAuthenticated false');
        var tryNum = theState.numTries++;

        var errorOpts = {
          exhausted: tryNum > maxTries,
          msg: 'Incorrect code - please try again'
        };

        //console.log(errorOpts);
        this.handleConfirmationCodeError(errorOpts);
      }
    } catch {
      this.handleConfirmationCodeError({
        exhausted: true,
        msg: 'You have entered the incorrect code too many times. Please try again.'
      });
      //this.props.confirmationCodeError({ exhausted: true, msg:'You have entered the incorrect code too many times. Please try again.' });
    }
    //console.log('^^ END submitConfirmationCode ^^');

    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <div>
        {this.state.exhausted && (
          <>
            <h3>Too many attempts.</h3>
            {this.state.confirmationCodeErrorMessage !== '' ? (
              <h5 className="error">{this.state.confirmationCodeErrorMessage}</h5>
            ) : null}
            <br />
            <button onClick={this.returnToLogin}>Login</button>
          </>
        )}
        {!this.state.exhausted && (
          <>
            {this.state.confirmationCodeErrorMessage !== '' ? (
              <h5 className="error">{this.state.confirmationCodeErrorMessage}</h5>
            ) : null}

            <div className="container">
              <div className="form-group-header">
                Please enter the SecureSend confirmation code just sent to{' '}
                {this.state.cognitoUser.username}
              </div>
              <input
                autoFocus
                name="confirmationCode"
                className="form-control"
                onChange={this.onChangeConfirmationCode}
                placeholder="Confirmation Code"
                ref={(elem) => (this.confirmationCodeInput = elem)}
                disabled={this.state.loading}
              />
            </div>
            <br />
            <button
              className="btn btn-primary m-1"
              onClick={this.submitConfirmationCode.bind(this, this.state)}
              disabled={this.state.loading}
            >
              Submit Code {this.state.loading && <FontAwesomeIcon icon={faSpinner} spin />}
            </button>
          </>
        )}
      </div>
    );
  }
}

export default AuthConfirmationCode;
