export const updateSecureSendRequestFiles = /* GraphQL */ `
  mutation UpdateSecureSendRequestFileViews(
    $input: UpdateSecureSendRequestFilesInput!
    $condition: ModelSecureSendRequestFilesConditionInput
  ) {
    updateSecureSendRequestFiles(input: $input, condition: $condition) {
      views
      maxViews
      firstViewedAt
    }
  }
`;

export const updateSecureSendRequestFirstLogin = /* GraphQL */ `
  mutation UpdateSecureSendRequests(
    $input: UpdateSecureSendRequestsInput!
    $condition: ModelSecureSendRequestsConditionInput
  ) {
    updateSecureSendRequests(input: $input, condition: $condition) {
        ssrequest_id
        org
        firstLogin
    }
  }
`;
