import React, { Component } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  //formatPhoneNumber,
  //formatPhoneNumberIntl,
  isValidPhoneNumber
  //isPossiblePhoneNumber
} from 'react-phone-number-input';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const loginMediumOpts = [
  {
    value: 'email',
    text: '',
    title: 'Login using eMail',
    icon: <i className="fa fa-envelope-o fa-fw"></i>
  },
  {
    value: 'mobile',
    text: '',
    title: 'Login using Mobile',
    icon: <i className="fa fa-mobile fa-fw"></i>
  }
];

class AuthSignIn extends Component {
  constructor(props) {
    super(props);

    this.loginMediumInputEmail = null;
    this.loginMediumInputMobile = null;

    this.state = {
      formUsername: this.props.username,
      emailValue: '',
      phoneValue: '',
      formUsernameValidationError: undefined,
      defaultCountry: 'AU',
      selectedLoginMediumOption: this.props.pref && this.props.pref === 'sms' ? 'mobile' : 'email',
      loginMediumOptsPrefIdx: this.props.pref && this.props.pref === 'sms' ? 1 : 0,
      disclaimerAcked: this.props.disclaimer === ''
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://kit.fontawesome.com/2f38ffacd3.js';
    script.async = true;
    document.body.appendChild(script);

    if (this.state.loginMediumOptsPrefIdx === 1) {
      //this.loginMediumInputMobile.focus();
    } else {
      this.loginMediumInputEmail.focus();
    }
    //console.log(this.props.orgData);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedLoginMediumOption !== this.state.selectedLoginMediumOption) {
      this.setState({
        formUsername: '',
        phoneValue: '',
        emailValue: '',
        formUsernameValidationError: undefined
      });

      if (this.state.selectedLoginMediumOption === 'email') {
        this.loginMediumInputEmail.focus();
      }
      if (this.state.selectedLoginMediumOption === 'mobile') {
        this.loginMediumInputMobile.focus();
      }
    }
  }

  onChangeFormUsername = (e) => {
    this.setState({
      formUsername: ''
    });
  };

  loginClicked = (e) => {
    if (this.state.formUsername !== '') {
      this.props.authSignClicked(this.state.formUsername);
    }
  };

  isPhoneNumberValid = (num) => {
    var isValid = num ? (isValidPhoneNumber(num) ? true : false) : undefined;
    return isValid;
  };

  isEmailAddressValid = (emailAdd) => {
    return true;
  };

  setPhoneValue = (e) => {
    if (this.isPhoneNumberValid(e)) {
      this.setState({
        phoneValue: e,
        formUsernameValidationError: false,
        formUsername: e
      });
    } else {
      this.setState({
        formUsernameValidationError: true
      });
    }
  };

  setEmailValue = (e) => {
    if (this.isEmailAddressValid(e.target.value)) {
      this.setState({
        emailValue: e.target.value,
        formUsername: e.target.value
      });
    }
  };

  handleDiscalaimerAckChange = (e) => {
    this.setState({
      disclaimerAcked: e.target.checked
    });
  };

  handleLoginMediumChange = (e) => {
    this.setState({
      selectedLoginMediumOption: e.value
    });
  };

  render() {
    return (
      <div className="text-center">
        {/*<h4>Please enter your email address or phone number.</h4>*/}

        {this.props.loginPageError !== '' ? (
          <div className="alert alert-danger">{this.props.loginPageError}</div>
        ) : null}
        <br />

        <div className="container">
          <div className="form-group-header">Please enter your email address or phone number.</div>
          <div className="row no-gutters">
            <div className="col-sm-auto mb-1">
              <Select
                placeholder=""
                value={this.selectedLoginMediumOption}
                defaultValue={loginMediumOpts[this.state.loginMediumOptsPrefIdx]}
                options={loginMediumOpts}
                onChange={this.handleLoginMediumChange}
                getOptionLabel={(e) => (
                  <div title={e.title} style={{ display: 'flex', alignItems: 'center' }}>
                    {e.icon}
                  </div>
                )}
                isDisabled={this.props.loading}
              />
            </div>

            {this.state.selectedLoginMediumOption === 'email' ? (
              <div className="col">
                <input
                  id="loginMediumEmail"
                  className="form-control"
                  type="email"
                  placeholder="Email address"
                  onChange={this.setEmailValue}
                  ref={(elem) => (this.loginMediumInputEmail = elem)}
                  disabled={this.props.loading}
                />
              </div>
            ) : null}

            {this.state.selectedLoginMediumOption === 'mobile' ? (
              <div className="col">
                <PhoneInput
                  id="loginMediumMobile"
                  ref={(elem) => (this.loginMediumInputMobile = elem)}
                  international
                  countryCallingCodeEditable={false}
                  countryOptionsOrder={['AU', 'NZ', '|', '...']}
                  placeholder="Enter phone number"
                  value={this.state.phoneValue}
                  onChange={this.setPhoneValue}
                  defaultCountry={this.state.defaultCountry}
                  error={
                    this.state.formUsernameValidationError !== undefined
                      ? this.state.formUsernameValidationError
                        ? 'Invalid phone number'
                        : undefined
                      : 'Phone number required'
                  }
                  disabled={this.props.loading}
                />
              </div>
            ) : null}
          </div>
        </div>

        <br />
        {this.props.disclaimer !== '' && (
          <div className="disclaimer alert alert-warning center-block">
            <div
              className="disclaimerText"
              dangerouslySetInnerHTML={{ __html: this.props.orgData.disclaimerText }}
            ></div>
            <div className="disclaimerAck">
              <label>
                <input
                  onChange={this.handleDiscalaimerAckChange}
                  type="checkbox"
                  value="1"
                  name="disclaimerAck"
                />{' '}
                {this.props.orgData.disclaimerAckLabel}
              </label>
            </div>
          </div>
        )}

        <br />
        <button
          className="btn btn-primary m-1"
          disabled={
            this.props.loading ||
            !this.state.disclaimerAcked ||
            this.state.formUsername === '' ||
            (this.state.formUsernameValidationError !== undefined &&
              this.state.formUsernameValidationError)
          }
          onClick={this.loginClicked}
        >
          Login {this.props.loading && <FontAwesomeIcon icon={faSpinner} spin />}
        </button>
      </div>
    );
  }
}

export default AuthSignIn;
