/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSecureSendEntryPoints = /* GraphQL */ `
  mutation CreateSecureSendEntryPoints(
    $input: CreateSecureSendEntryPointsInput!
    $condition: ModelSecureSendEntryPointsConditionInput
  ) {
    createSecureSendEntryPoints(input: $input, condition: $condition) {
      tag
      org
      email
      mobile
      pref
      upload
      ssrequest_ids
      invitation
      ts
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureSendEntryPoints = /* GraphQL */ `
  mutation UpdateSecureSendEntryPoints(
    $input: UpdateSecureSendEntryPointsInput!
    $condition: ModelSecureSendEntryPointsConditionInput
  ) {
    updateSecureSendEntryPoints(input: $input, condition: $condition) {
      tag
      org
      email
      mobile
      pref
      upload
      ssrequest_ids
      invitation
      ts
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureSendEntryPoints = /* GraphQL */ `
  mutation DeleteSecureSendEntryPoints(
    $input: DeleteSecureSendEntryPointsInput!
    $condition: ModelSecureSendEntryPointsConditionInput
  ) {
    deleteSecureSendEntryPoints(input: $input, condition: $condition) {
      tag
      org
      email
      mobile
      pref
      upload
      ssrequest_ids
      invitation
      ts
      createdAt
      updatedAt
    }
  }
`;
export const createSecureSendRequests = /* GraphQL */ `
  mutation CreateSecureSendRequests(
    $input: CreateSecureSendRequestsInput!
    $condition: ModelSecureSendRequestsConditionInput
  ) {
    createSecureSendRequests(input: $input, condition: $condition) {
      ssrequest_id
      org
      firstname
      surname
      title
      msg
      validFrom
      validTo
      patient {
        uuid
        patientid
        mrn
        firstname
        surname
        pref
        title
        suburb
        postcode
      }
      enteredBy {
        firstname
        surname
        phone
        email
        userid
      }
      ts
      firstLogin
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureSendRequests = /* GraphQL */ `
  mutation UpdateSecureSendRequests(
    $input: UpdateSecureSendRequestsInput!
    $condition: ModelSecureSendRequestsConditionInput
  ) {
    updateSecureSendRequests(input: $input, condition: $condition) {
      ssrequest_id
      org
      firstname
      surname
      title
      msg
      validFrom
      validTo
      patient {
        uuid
        patientid
        mrn
        firstname
        surname
        pref
        title
        suburb
        postcode
      }
      enteredBy {
        firstname
        surname
        phone
        email
        userid
      }
      ts
      firstLogin
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureSendRequests = /* GraphQL */ `
  mutation DeleteSecureSendRequests(
    $input: DeleteSecureSendRequestsInput!
    $condition: ModelSecureSendRequestsConditionInput
  ) {
    deleteSecureSendRequests(input: $input, condition: $condition) {
      ssrequest_id
      org
      firstname
      surname
      title
      msg
      validFrom
      validTo
      patient {
        uuid
        patientid
        mrn
        firstname
        surname
        pref
        title
        suburb
        postcode
      }
      enteredBy {
        firstname
        surname
        phone
        email
        userid
      }
      ts
      firstLogin
      createdAt
      updatedAt
    }
  }
`;
export const createSecureSendRequestFiles = /* GraphQL */ `
  mutation CreateSecureSendRequestFiles(
    $input: CreateSecureSendRequestFilesInput!
    $condition: ModelSecureSendRequestFilesConditionInput
  ) {
    createSecureSendRequestFiles(input: $input, condition: $condition) {
      ordinality
      ssrequest_id
      file_uuid
      bucket
      location
      filename
      type
      category
      description
      views
      maxViews
      dl
      firstViewedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureSendRequestFiles = /* GraphQL */ `
  mutation UpdateSecureSendRequestFiles(
    $input: UpdateSecureSendRequestFilesInput!
    $condition: ModelSecureSendRequestFilesConditionInput
  ) {
    updateSecureSendRequestFiles(input: $input, condition: $condition) {
      ordinality
      ssrequest_id
      file_uuid
      bucket
      location
      filename
      type
      category
      description
      views
      maxViews
      dl
      firstViewedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureSendRequestFiles = /* GraphQL */ `
  mutation DeleteSecureSendRequestFiles(
    $input: DeleteSecureSendRequestFilesInput!
    $condition: ModelSecureSendRequestFilesConditionInput
  ) {
    deleteSecureSendRequestFiles(input: $input, condition: $condition) {
      ordinality
      ssrequest_id
      file_uuid
      bucket
      location
      filename
      type
      category
      description
      views
      maxViews
      dl
      firstViewedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPalCareOrganisations = /* GraphQL */ `
  mutation CreatePalCareOrganisations(
    $input: CreatePalCareOrganisationsInput!
    $condition: ModelPalCareOrganisationsConditionInput
  ) {
    createPalCareOrganisations(input: $input, condition: $condition) {
      org
      name
      logo
      disclaimerText
      disclaimerAckLabel
      defaultCountryCode
      ssReplyToEmail
      ssBaseURL
      ssSubjectLine
      BCC
      createdAt
      updatedAt
    }
  }
`;
export const updatePalCareOrganisations = /* GraphQL */ `
  mutation UpdatePalCareOrganisations(
    $input: UpdatePalCareOrganisationsInput!
    $condition: ModelPalCareOrganisationsConditionInput
  ) {
    updatePalCareOrganisations(input: $input, condition: $condition) {
      org
      name
      logo
      disclaimerText
      disclaimerAckLabel
      defaultCountryCode
      ssReplyToEmail
      ssBaseURL
      ssSubjectLine
      BCC
      createdAt
      updatedAt
    }
  }
`;
export const deletePalCareOrganisations = /* GraphQL */ `
  mutation DeletePalCareOrganisations(
    $input: DeletePalCareOrganisationsInput!
    $condition: ModelPalCareOrganisationsConditionInput
  ) {
    deletePalCareOrganisations(input: $input, condition: $condition) {
      org
      name
      logo
      disclaimerText
      disclaimerAckLabel
      defaultCountryCode
      ssReplyToEmail
      ssBaseURL
      ssSubjectLine
      BCC
      createdAt
      updatedAt
    }
  }
`;
export const createSecureSendLog = /* GraphQL */ `
  mutation CreateSecureSendLog(
    $input: CreateSecureSendLogInput!
    $condition: ModelSecureSendLogConditionInput
  ) {
    createSecureSendLog(input: $input, condition: $condition) {
      ssrequest_id
      ts
      logLevel
      billable
      who
      targetId
      targetLabel
      action
      details
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureSendLog = /* GraphQL */ `
  mutation UpdateSecureSendLog(
    $input: UpdateSecureSendLogInput!
    $condition: ModelSecureSendLogConditionInput
  ) {
    updateSecureSendLog(input: $input, condition: $condition) {
      ssrequest_id
      ts
      logLevel
      billable
      who
      targetId
      targetLabel
      action
      details
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureSendLog = /* GraphQL */ `
  mutation DeleteSecureSendLog(
    $input: DeleteSecureSendLogInput!
    $condition: ModelSecureSendLogConditionInput
  ) {
    deleteSecureSendLog(input: $input, condition: $condition) {
      ssrequest_id
      ts
      logLevel
      billable
      who
      targetId
      targetLabel
      action
      details
      createdAt
      updatedAt
    }
  }
`;
export const createSecureSendLogBillable = /* GraphQL */ `
  mutation CreateSecureSendLogBillable(
    $input: CreateSecureSendLogBillableInput!
    $condition: ModelSecureSendLogBillableConditionInput
  ) {
    createSecureSendLogBillable(input: $input, condition: $condition) {
      org
      ts
      ssrequest_id
      who
      targetId
      targetLabel
      action
      medium
      units
      recipient
      payload
      details
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureSendLogBillable = /* GraphQL */ `
  mutation UpdateSecureSendLogBillable(
    $input: UpdateSecureSendLogBillableInput!
    $condition: ModelSecureSendLogBillableConditionInput
  ) {
    updateSecureSendLogBillable(input: $input, condition: $condition) {
      org
      ts
      ssrequest_id
      who
      targetId
      targetLabel
      action
      medium
      units
      recipient
      payload
      details
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureSendLogBillable = /* GraphQL */ `
  mutation DeleteSecureSendLogBillable(
    $input: DeleteSecureSendLogBillableInput!
    $condition: ModelSecureSendLogBillableConditionInput
  ) {
    deleteSecureSendLogBillable(input: $input, condition: $condition) {
      org
      ts
      ssrequest_id
      who
      targetId
      targetLabel
      action
      medium
      units
      recipient
      payload
      details
      createdAt
      updatedAt
    }
  }
`;
export const createSecureSendLogStatusUpdates = /* GraphQL */ `
  mutation CreateSecureSendLogStatusUpdates(
    $input: CreateSecureSendLogStatusUpdatesInput!
    $condition: ModelSecureSendLogStatusUpdatesConditionInput
  ) {
    createSecureSendLogStatusUpdates(input: $input, condition: $condition) {
      ssrequest_id
      ts
      org
      status
      details
      createdAt
      updatedAt
    }
  }
`;
export const updateSecureSendLogStatusUpdates = /* GraphQL */ `
  mutation UpdateSecureSendLogStatusUpdates(
    $input: UpdateSecureSendLogStatusUpdatesInput!
    $condition: ModelSecureSendLogStatusUpdatesConditionInput
  ) {
    updateSecureSendLogStatusUpdates(input: $input, condition: $condition) {
      ssrequest_id
      ts
      org
      status
      details
      createdAt
      updatedAt
    }
  }
`;
export const deleteSecureSendLogStatusUpdates = /* GraphQL */ `
  mutation DeleteSecureSendLogStatusUpdates(
    $input: DeleteSecureSendLogStatusUpdatesInput!
    $condition: ModelSecureSendLogStatusUpdatesConditionInput
  ) {
    deleteSecureSendLogStatusUpdates(input: $input, condition: $condition) {
      ssrequest_id
      ts
      org
      status
      details
      createdAt
      updatedAt
    }
  }
`;
