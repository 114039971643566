/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSecureSendEntryPoints = /* GraphQL */ `
  query GetSecureSendEntryPoints($tag: String!) {
    getSecureSendEntryPoints(tag: $tag) {
      tag
      org
      email
      mobile
      pref
      upload
      ssrequest_ids
      invitation
      ts
      createdAt
      updatedAt
    }
  }
`;
export const listSecureSendEntryPoints = /* GraphQL */ `
  query ListSecureSendEntryPoints(
    $tag: String
    $filter: ModelSecureSendEntryPointsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSecureSendEntryPoints(
      tag: $tag
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tag
        org
        email
        mobile
        pref
        upload
        ssrequest_ids
        invitation
        ts
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSecureSendRequests = /* GraphQL */ `
  query GetSecureSendRequests($ssrequest_id: String!) {
    getSecureSendRequests(ssrequest_id: $ssrequest_id) {
      ssrequest_id
      org
      firstname
      surname
      title
      msg
      validFrom
      validTo
      patient {
        uuid
        patientid
        mrn
        firstname
        surname
        pref
        title
        suburb
        postcode
      }
      enteredBy {
        firstname
        surname
        phone
        email
        userid
      }
      ts
      firstLogin
      createdAt
      updatedAt
    }
  }
`;
export const listSecureSendRequests = /* GraphQL */ `
  query ListSecureSendRequests(
    $ssrequest_id: String
    $filter: ModelSecureSendRequestsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSecureSendRequests(
      ssrequest_id: $ssrequest_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ssrequest_id
        org
        firstname
        surname
        title
        msg
        validFrom
        validTo
        patient {
          uuid
          patientid
          mrn
          firstname
          surname
          pref
          title
          suburb
          postcode
        }
        enteredBy {
          firstname
          surname
          phone
          email
          userid
        }
        ts
        firstLogin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSecureSendRequestFiles = /* GraphQL */ `
  query GetSecureSendRequestFiles($ssrequest_id: String!, $ordinality: Int!) {
    getSecureSendRequestFiles(
      ssrequest_id: $ssrequest_id
      ordinality: $ordinality
    ) {
      ordinality
      ssrequest_id
      file_uuid
      bucket
      location
      filename
      type
      category
      description
      views
      maxViews
      dl
      firstViewedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSecureSendRequestFiles = /* GraphQL */ `
  query ListSecureSendRequestFiles(
    $ssrequest_id: String
    $ordinality: ModelIntKeyConditionInput
    $filter: ModelSecureSendRequestFilesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSecureSendRequestFiles(
      ssrequest_id: $ssrequest_id
      ordinality: $ordinality
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ordinality
        ssrequest_id
        file_uuid
        bucket
        location
        filename
        type
        category
        description
        views
        maxViews
        dl
        firstViewedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPalCareOrganisations = /* GraphQL */ `
  query GetPalCareOrganisations($org: String!) {
    getPalCareOrganisations(org: $org) {
      org
      name
      logo
      disclaimerText
      disclaimerAckLabel
      defaultCountryCode
      ssReplyToEmail
      ssBaseURL
      ssSubjectLine
      BCC
      createdAt
      updatedAt
    }
  }
`;
export const listPalCareOrganisations = /* GraphQL */ `
  query ListPalCareOrganisations(
    $org: String
    $filter: ModelPalCareOrganisationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPalCareOrganisations(
      org: $org
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        org
        name
        logo
        disclaimerText
        disclaimerAckLabel
        defaultCountryCode
        ssReplyToEmail
        ssBaseURL
        ssSubjectLine
        BCC
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSecureSendLog = /* GraphQL */ `
  query GetSecureSendLog($ssrequest_id: String!, $ts: AWSDateTime!) {
    getSecureSendLog(ssrequest_id: $ssrequest_id, ts: $ts) {
      ssrequest_id
      ts
      logLevel
      billable
      who
      targetId
      targetLabel
      action
      details
      createdAt
      updatedAt
    }
  }
`;
export const listSecureSendLogs = /* GraphQL */ `
  query ListSecureSendLogs(
    $ssrequest_id: String
    $ts: ModelStringKeyConditionInput
    $filter: ModelSecureSendLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSecureSendLogs(
      ssrequest_id: $ssrequest_id
      ts: $ts
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ssrequest_id
        ts
        logLevel
        billable
        who
        targetId
        targetLabel
        action
        details
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSecureSendLogBillable = /* GraphQL */ `
  query GetSecureSendLogBillable($org: String!, $ts: AWSDateTime!) {
    getSecureSendLogBillable(org: $org, ts: $ts) {
      org
      ts
      ssrequest_id
      who
      targetId
      targetLabel
      action
      medium
      units
      recipient
      payload
      details
      createdAt
      updatedAt
    }
  }
`;
export const listSecureSendLogBillables = /* GraphQL */ `
  query ListSecureSendLogBillables(
    $org: String
    $ts: ModelStringKeyConditionInput
    $filter: ModelSecureSendLogBillableFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSecureSendLogBillables(
      org: $org
      ts: $ts
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        org
        ts
        ssrequest_id
        who
        targetId
        targetLabel
        action
        medium
        units
        recipient
        payload
        details
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSecureSendLogStatusUpdates = /* GraphQL */ `
  query GetSecureSendLogStatusUpdates(
    $ssrequest_id: String!
    $ts: AWSDateTime!
  ) {
    getSecureSendLogStatusUpdates(ssrequest_id: $ssrequest_id, ts: $ts) {
      ssrequest_id
      ts
      org
      status
      details
      createdAt
      updatedAt
    }
  }
`;
export const listSecureSendLogStatusUpdates = /* GraphQL */ `
  query ListSecureSendLogStatusUpdates(
    $ssrequest_id: String
    $ts: ModelStringKeyConditionInput
    $filter: ModelSecureSendLogStatusUpdatesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSecureSendLogStatusUpdates(
      ssrequest_id: $ssrequest_id
      ts: $ts
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ssrequest_id
        ts
        org
        status
        details
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
