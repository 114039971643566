import React, { Component } from 'react';
//import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify'
import { Storage } from 'aws-amplify'

//import PDFViewer from '../components/PDFViewer/PDFViewer'
//import PDFJSBackend from '../backends/pdfjs';

//import { createRequire } from 'module';

import * as mutations from '../graphql/mutations'; 
import * as queries from '../graphql/queries';
import * as customMutations from '../custom/customMutations';
import * as secureSendActions from '../custom/secureSendActions';

import * as customQueries from '../custom/customQueries';

const pdfjsURL = window.location.protocol + '//' + window.location.host + '/lib/pdfJS/2_9_359-legacy/web/viewer.html?file=';
const pdfjsNoDLoadURL = window.location.protocol + '//' + window.location.host + '/lib/pdfJS/2_9_359-legacy/web/viewer_noDL.html?file=';

class SecureSendRequestJS extends Component {
    constructor(props) {
        super(props);

        this.state = {
            entrypointTag: this.props.entrypointTag,
            secureSendRequestIDs: this.props.secureSendRequestIDs,
            secureSendRequestExpired: false,
            org: this.props.org,
            iframeURL: '',

            currentFileViewIndex: -1, 
            currentFileViewKey: '',
            fileUrl: '',
            pageNumber: 1, 
            numPages: null,
            ExampleContainerClass: 'Example__container hideElem',
            hiddenClass: 'hideElem',
            entryPoint: {},
            listedFiles: {},
            secureSendRequests: []            // set as an array - will be singular for now.
        };
    }

    async componentDidMount() {
        this.getSecureSendRequest(this.state.secureSendRequestIDs[0]);
    }

    setError = (errStruct) => {
        this.props.setError(errStruct);
    };

    listFileInState = (file_ordinality, fileinfo) => {
        var stateKey = 'listedfile_' + file_ordinality;
        this.setState({
            [stateKey]: fileinfo
        });
        //console.log(stateKey);
        //console.log(this.state[stateKey]);
    }
    updateFileViewsInState = (file_ordinality, views) => {
        var stateKey = 'listedfile_' + file_ordinality;

        try {
            var currState = this.state[stateKey];
            currState.views = views;

            this.setState({
                [stateKey]: currState
            });
        } catch (e){
            //console.log('error in updateFileViewsInState:');
            //console.log(e);
        }
    }

    getSecureSendRequest = (requestUUID) => {
        API.graphql({query: queries.getSecureSendRequests, variables: {ssrequest_id: requestUUID} }).then(
            (requestData) => {
                if ( requestData.data.getSecureSendRequests.validTo !== undefined && requestData.data.getSecureSendRequests.validTo !== null && new Date(requestData.data.getSecureSendRequests.validTo) < new Date() ){
                    this.setError({header: 'This SecureSend Request has expired.', msg: 'This request expired on ' + new Date(requestData.data.getSecureSendRequests.validTo) + '.<br />Please contact the sender for more information.'});
                    this.setState({
                        secureSendRequestExpired: true
                    });
                } else {
                    this.setState({
                        secureSendRequestExpired: false
                    });
                }
                API.graphql({ query: queries.listSecureSendRequestFiles, variables: {ssrequest_id: requestUUID} }).then(
                    (filesData) => {
                        var thisRequest = requestData.data.getSecureSendRequests;
                        thisRequest.files = filesData.data.listSecureSendRequestFiles.items;

                        Array.from(thisRequest.files, (aFile, SSReqFile_idx) => (
                            this.listFileInState(aFile.ordinality, aFile)
                        ));

                        this.setState({
                            secureSendRequests: [thisRequest]
                        });
                    },
                    (err) => {
                        this.setError({header: 'An error occured retrieving your SecureSend Request Files', msg: JSON.stringify(err)});
                    }
                );
            },
            (err) => {
                this.setError({header: 'An error occured retrieving your SecureSend Request', msg: JSON.stringify(err)});
            }
        );

    }

    getFileOptions = (SSReq_idx, SSReqFile_idx) => {
        var theSSReq = this.state.secureSendRequests;

        var storageOpts = {
            bucket: Storage._config.AWSS3.bucket,
            expires: 60,
            customPrefix: {
                public: '',
                private: '',
                protected: ''
            }
        };

        if (this.state.secureSendRequests[SSReq_idx].files[SSReqFile_idx].bucket && this.state.secureSendRequests[SSReq_idx].files[SSReqFile_idx].bucket !== '' ){
            storageOpts.bucket = this.state.secureSendRequests[SSReq_idx].files[SSReqFile_idx].bucket
        }

        var thisFilename = this.state.secureSendRequests[SSReq_idx].files[SSReqFile_idx].filename;
        var objKey = thisFilename;
        if (this.state.secureSendRequests[SSReq_idx].files[SSReqFile_idx].location){
            objKey = this.state.secureSendRequests[SSReq_idx].files[SSReqFile_idx].location;
        }

        return {
            ssRequest: theSSReq,
            storageOpts: storageOpts,
            objKey: objKey,
            download: true,
            filename: thisFilename
        }
    }

    secureSendLog = (action, SSReq_idx, SSReqFile_idx) => {
        var logPayload = {
            file: this.state.secureSendRequests[SSReq_idx].files[SSReqFile_idx]
        }
        var logLevel = 5;

        var logOpts = secureSendActions.getSecureSendLogInput(this.state.secureSendRequests[SSReq_idx].ssrequest_id, this.props.cognitoUser, action, logLevel, logPayload);
        API.graphql(graphqlOperation(mutations.createSecureSendLog, {
            input: logOpts.standardLog
        }));
    }

    // https://docs.amplify.aws/lib/storage/download/q/platform/js/
    //  Also - CORS on bucket scnd.test.pal.care for localhost needed to ExposeHeader: ETag
    downloadBlob = (blob, filename) => {
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    handleFileDownloadFromDB = (SSReq_idx, SSReqFile_idx, event) => {
        var fileOpts = this.getFileOptions(SSReq_idx, SSReqFile_idx);
        fileOpts.storageOpts.download = true;

        Storage.get(fileOpts.objKey, fileOpts.storageOpts).then(
            data => {
                this.incrementFileView(this.state.secureSendRequests[SSReq_idx].ssrequest_id, this.state.secureSendRequests[SSReq_idx].files[SSReqFile_idx].ordinality);
                this.secureSendLog('File Download', SSReq_idx, SSReqFile_idx);
                //window.open(data, '_blank');
                this.downloadBlob(data.Body, fileOpts.filename);
            },
            err => {
                //console.log('error downloading file');
                //console.log(err);
            }
        );
    }

    handleFileViewFromDB = (SSReq_idx, SSReqFile_idx, event) => {
        event.preventDefault();

        var fileOpts = this.getFileOptions(SSReq_idx, SSReqFile_idx);
        Storage.get(fileOpts.objKey, fileOpts.storageOpts).then(
            data => {
                //console.log(data);
                this.incrementFileView(this.state.secureSendRequests[SSReq_idx].ssrequest_id, this.state.secureSendRequests[SSReq_idx].files[SSReqFile_idx].ordinality);
                this.secureSendLog('File View', SSReq_idx, SSReqFile_idx);

                var pdfURL = (this.state.secureSendRequests[SSReq_idx].files[SSReqFile_idx].dl?pdfjsURL:pdfjsNoDLoadURL) + encodeURIComponent(data);
                //pdfjsNoDLoadURL
                //var pdfURL = encodeURIComponent(data);

                //window.open(pdfURL, '_blank');
                this.setState({
                    iframeURL: pdfURL,
                    currentFileViewKey: (''+SSReq_idx+'_'+SSReqFile_idx)
                });

            }
        )
        .catch(err => { 
            //console.log('error fetching file for viewing');
            //console.log(err);
        });
    }

    incrementFileView(ssrequest_id, file_ordinality){
        API.graphql({query: customQueries.getSecureSendRequestFileViews, variables: {ssrequest_id: ssrequest_id, ordinality: file_ordinality} }).then(
            currViewInfo => {
                var currNumViews = (currViewInfo.data.getSecureSendRequestFiles.views !== null)?currViewInfo.data.getSecureSendRequestFiles.views:0;
                var updateOpts = {
                    input: {
                        ssrequest_id: ssrequest_id,
                        ordinality: file_ordinality,
                        views: (currNumViews+1)
                    }
                };

                if (currViewInfo.data.getSecureSendRequestFiles.firstViewedAt === null){
                    updateOpts.input.firstViewedAt = new Date().toISOString();

                    var logData = {
                        ssrequest_id: ssrequest_id,
                        ts: updateOpts.input.firstViewedAt,
                        org: this.state.org,
                        status: 'viewed',   // statusTags.viewed
                        details: '{"file_ordinality": ' + file_ordinality + '}'
                    };
                    API.graphql(graphqlOperation(mutations.createSecureSendLogStatusUpdates, {
                        input: logData
                    }));
                }

                API.graphql(graphqlOperation(customMutations.updateSecureSendRequestFiles, updateOpts)).then(
                    (updatedCountRes) => {
                        this.updateFileViewsInState(file_ordinality, updatedCountRes.data.updateSecureSendRequestFiles.views);
                    },
                    (err) => {
                        //console.log(err);
                    }
                );
            },
            err => {}
        );
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
        this.setState( { pageNumber: 1  });
      }

    handlePageNavigation = (progression) => {
        this.setState({
            pageNumber: this.state.pageNumber+progression
        });
    }

    handlePagePrevious = event => {
        this.handlePageNavigation(-1);
    }
    handlePageNext = event => {
        this.handlePageNavigation(1);
    }

    render () {
        return (
            <div className="FileRequestWrapper">
                <div className="AvailableRequests">
                {
                    Array.from(this.state.secureSendRequests, (SSReq, SSReq_idx) => (
                        <div className="SecureSendRequestWrapper fileWrapper" key='{SSReq_idx}_{SSReq.ssrequest_id}' ssreq="{SSReq.ssrequest_id}" idx="{SSReq_idx}">
                            {SSReq.msg !== "" && (<div className="headerNote">{SSReq.msg}</div>)}
                            <div className="tableHeader">
                                {SSReq.patient.title} {SSReq.patient.surname}, {SSReq.patient.firstname} {SSReq.patient.pref !== "" && (<span className="preferredName">( {SSReq.patient.pref} )</span>)}
                            </div>
                            <table className="ssRequestFiles">
                                <thead>
                                    <tr>
                                        <th>Filename</th>
                                        {/*
                                        <th style="display:none;">Category</th>
                                        <th style="display:none;">Description</th>
                                        */}
                                        <th>Views</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/*SHould sep this out into a component - easier to handle view/hide logic */}
                                {
                                    Array.from(SSReq.files, (aFile, SSReqFile_idx) => (
                                        <tr key={aFile.file_uuid} reqidx={SSReq_idx} fileidx={SSReqFile_idx} 
                                                    className={(this.state.currentFileViewKey === (''+SSReq_idx+'_'+SSReqFile_idx)?"bg-warning-light currentlyViewing":"")}>
                                            <td>{aFile.filename}</td>
                                            {/*
                                            <td>{aFile.category}</td>
                                            <td>{aFile.description}</td>
                                            */}
                                            <td>{this.state['listedfile_' + aFile.ordinality].views} <span className={(aFile.maxViews>0)?"maxviews":"maxviews hideElem"}>of {aFile.maxViews}</span>
                                            </td>
                                            <td>
                                                {(!this.state.secureSendRequestExpired) &&
                                                    <div className={(aFile.maxViews !== null && aFile.views >= aFile.maxViews)?"btn-group pull-right maxViewsExhausted":"btn-group pull-right"}>
                                                        <button className="btn btn-xs btn-primary" type="button" disabled={(this.state.currentFileViewKey === (''+SSReq_idx+'_'+SSReqFile_idx) || (aFile.maxViews !== null && aFile.views >= aFile.maxViews))} onClick={this.handleFileViewFromDB.bind(this, SSReq_idx, SSReqFile_idx)}> View </button>
                                                            <button className={(!aFile.dl)?"btn btn-xs btn-primary invisible":"btn btn-xs btn-primary"} type="button" disabled={!aFile.dl} onClick={this.handleFileDownloadFromDB.bind(this, SSReq_idx, SSReqFile_idx)}> Download </button>
                                                    </div>
                                                }
                                                {(this.state.secureSendRequestExpired) &&
                                                    <em>Expired</em>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    ))
                }
                </div>
                {/*this.state.iframeURL !== "" && (
                    <PDFViewer 
                        backend={PDFJSBackend}
                        src={this.state.iframeURL}
                    />
                )*/}
                {this.state.iframeURL !== "" && (
                    <div>
                        <iframe id={this.state.currentFileViewKey} className="fileViewFrame" title="Document Viewer" name="fileViewFrame" src={this.state.iframeURL} frameBorder="0"></iframe>
                    </div>

                )}
            </div>
        )
    }

}

export default SecureSendRequestJS;