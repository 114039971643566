//import React, { useState, useEffect } from 'react';
import React, { Component } from 'react';

//import logo from './logo.svg';
import AppLogo from './assets/images/palcare_logo.jpg';
import './App.css';

//import { withAuthenticator } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify'

import AuthSignIn from './pages/authSignIn';
import AuthConfirmationCode from './pages/authConfirmationCode';

import SecureSendRequestJS from './components/secureSendRequestJS';
import * as secureSendActions from './custom/secureSendActions';

import * as mutations from './graphql/mutations';

//import * as apiQueries from './graphql/queries';
import * as customMutations from './custom/customMutations';
import * as customQueries from './custom/customQueries';
import * as consts from './custom/constants';

const pathname = window.location.pathname.replace(/^\/|\/$/g, '');

Auth.configure({
  authenticationFlowType: 'CUSTOM_AUTH',
  storage: sessionStorage
});

const DefaultUsername = '';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entrypointTag: pathname,
      logo: AppLogo,
      disclaimer: '',
      username: DefaultUsername,
      confirmationCode: '',
      confirmationCodeErrorMessage: '',
      cognitoUser: null,
      formType: 'validateEntryPoint',
      loginState: null,
      loggedIn: false,
      loginPageError: '',
      currErr: null,
      upload: false,
      ssrequest_ids: [],
      URLs: {},
      loading: false
    };
  }

  async componentDidMount() {
    this.validateEntryPoint();
    //console.log('this.state', this.state);
  }

  setError = (errStruct) => {
    this.setState({
      currErr: errStruct
    });

    if (errStruct !== undefined){
      if (errStruct.log !== undefined && errStruct.log){
        // SecureSendLog
        var logLevel = consts.errorLevels.usererror;  // increase log level (user error) ..

        if (errStruct.logLevel !== undefined){
          logLevel = errStruct.logLevel;
        }

        var thisUsername = this.state.username;
        try {
          thisUsername = this.state.cognitoUser.username;
        } catch (e){}

        var errAction = '';
        if (errStruct.errAction !== undefined){
          errAction = errStruct.errAction;
        } else {
          try {
            errAction = errStruct.header;
          } catch (e){}
        }

        /*
        var thisError = 'User Error';
        try {
          thisError += ' - ' + errStruct.header;
        } catch (e){}
        */

        var logOpts = secureSendActions.getSecureSendLogInput(this.state.ssrequest_ids[0], thisUsername, errAction, logLevel, errStruct);
        API.graphql(graphqlOperation(mutations.createSecureSendLog, {
          input: logOpts.standardLog
        }));
      }
    }
  };

  async checkEntryPointUser(theEntryPointTag) {
    if (theEntryPointTag === undefined) {
      theEntryPointTag = this.state.entrypointTag;
    }

    await Auth.currentUserInfo().then(
      (res) => {
        if (!res) {
          // not logged in:
          this.setState({
            currErr: null,
            formType: 'signIn'
          });
          return;
        } else {
          if (this.state.username === ''){
            this.setState({
              username: res.username,
              cognitoUser: res
            });
          }
        }

        // logged in - check user is allowed to access this entrypoint.
        //console.log(res); console.log(res.attributes.email); console.log(res.attributes.phone_number); console.table(this.state.URLs[this.state.entrypointTag]);

        var emailtocheck = res.attributes.email ? res.attributes.email : 'noemailspecified';
        var phonetocheck = res.attributes.phone_number
          ? res.attributes.phone_number
          : 'nophonenumberspecified';

        // check the DB for the EntryPoint & login detail match
        API.graphql({
          query: customQueries.listValidSecureSendEntryPoints,
          variables: {
            tag: theEntryPointTag,
            filter: {
              or: [{ email: { eq: emailtocheck } }, { mobile: { eq: phonetocheck } }]
            }
          }
        }).then(
          (res) => {
            if (res.data.listSecureSendEntryPoints.items.length > 0) {
              //console.log('Setting state from ' + this.state.formType + ' to loggedInUserValidated');
              //console.log('res.data.listSecureSendEntryPoints.items[0]'); console.log(res.data.listSecureSendEntryPoints.items[0]);
              this.setState({
                loggedIn: true,
                currErr: null,
                formType: 'loggedInUserValid',
                ssrequest_ids: res.data.listSecureSendEntryPoints.items[0].ssrequest_ids
              });

              // @@TODO #ADP-3389 - Log Status if this is the first login for this request... firstLogin
              API.graphql({
                query: customQueries.getSecureSendRequestFirstLogin,
                variables: {
                  ssrequest_id: this.state.ssrequest_ids[0]
                }
              }).then(
                (res) => {
                  try {
                    if (res.data.getSecureSendRequests.firstLogin === undefined || res.data.getSecureSendRequests.firstLogin === null || res.data.getSecureSendRequests.firstLogin === '' ){
                      var updateOpts = {
                        input: {
                          ssrequest_id: this.state.ssrequest_ids[0],
                          firstLogin: new Date().toISOString()
                        }
                      };
                      API.graphql(graphqlOperation(customMutations.updateSecureSendRequestFirstLogin, updateOpts)).then(
                        (updateFirstLoginRes) => {
                          // fire off a status update...
                          var logData = {
                            ssrequest_id: updateOpts.input.ssrequest_id,
                            ts: updateOpts.input.firstLogin,
                            org: updateFirstLoginRes.data.updateSecureSendRequests.org,
                            status: 'accessed',
                            details: '{"username": "' + this.state.username + '"}'
                          };
                          //secureSendActions.getSecureSendLogInput(this.state.ssrequest_ids[0], thisUsername, errAction, logLevel, errStruct);
                          API.graphql(graphqlOperation(mutations.createSecureSendLogStatusUpdates, {
                            input: logData
                          }));
                        },
                        (updateFirstLoginErr) => {
                            //console.log(updateFirstLoginErr);
                        }
                      );
                    }
                  } catch (err){}
                }
              );


            } else {
              this.setStateLoggedInUserInvalid();
            }
          },
          (err) => {
            //GUSGUS
            this.setError({
              header: 'An error has occured',
              msg: 'An error has occured validating your request. Please try again.',
              err: err,
              log: true,
              logLevel: consts.errorLevels.error
            });
          }
        );
      },
      (err) => {
        this.setState({
          currErr: null,
          formType: 'signIn'
        });
      }
    );
  }

  async validateEntryPoint() {
    // check the entrypoint is valid
    var entryPoint;

    //console.log('this.state.entrypointTag: ', this.state.entrypointTag);

    if (this.state.entrypointtag === 'lib/font-awesome/5.13.0/js/all.min.js') {
      return;
    }

    if (this.state.entrypointTag === '' || this.state.entrypointTag === '/') {
      this.setState({
        loggedIn: false,
        currErr: {
          header: 'Invalid Request',
          msg: 'We could not find a valid Secure Send request. Please follow the link you were sent or contact the sender for more information.'
        },
        formType: 'InvalidRequest',
        logo: AppLogo
      });
      return;
    }

    // check if the entrypointtag exists in this.state.validURLs[entrypointtag]
    if (this.state.URLs[this.state.entrypointTag]) {
      //console.log('Checking CACHE for entry point: ' + this.state.entrypointTag);
      entryPoint = this.state.URLs[this.state.entrypointTag];
    } else {
      //console.log('Checking DB for entry point: ' + this.state.entrypointTag);
      entryPoint = await API.graphql({
        query: customQueries.getValidSecureSendEntryPoint,
        variables: { tag: this.state.entrypointTag }
      }).then(
        (res) => {
          try {
            var retStruct = {
              valid: res.data.getSecureSendEntryPoints !== null,
              org: res.data.getSecureSendEntryPoints.org,
              pref: res.data.getSecureSendEntryPoints.pref,
              ssrequest_ids: res.data.getSecureSendEntryPoints.ssrequest_ids
              //, orgdata: consts.orgs[res.data.getSecureSendEntryPoints.org]
            };

            /*
            try {
              if (process.env.NODE_ENV === 'development'){
                console.log('[dev] entryPoint:', retStruct);
              }
            } catch(e){}
            */
            return retStruct;
          } catch (e) {
            return { valid: false, org: '', orgdata: { logo: AppLogo }, err: e };
          }
        },
        (err) => {
          return { valid: false, org: '', orgdata: { logo: AppLogo }, err: err };
        }
      );

      if (entryPoint.valid) {
        // get the ORG data:
        entryPoint.orgdata = await API.graphql({
          query: customQueries.getPalCareOrganisations,
          variables: { org: entryPoint.org }
        }).then(
          (orgRes) => {
              var retData = orgRes.data.getPalCareOrganisations;
              return retData;
          },
          (orgErr) => {
            return { valid: false, org: '', orgdata: { logo: AppLogo }, err: orgErr };
          }
        );

        if (entryPoint.orgdata === null){
          // get the default ORG data JUST for the purposes of logo/disclaimer etc. Use entryPoint.org for all further logging etc...:
          // @@TODO: Send an email/alert to PalCare staff that ORG is not setup.
          entryPoint.orgdata = await API.graphql({
            query: customQueries.getPalCareOrganisations,
            variables: { org: "*" }
          }).then(
            (orgRes) => {
              var retData = orgRes.data.getPalCareOrganisations;
              // change org back to that entry point ORG value for any future logging/processing. 
              try {
                retData.org = entryPoint.org;
              } catch (e){}
              return retData;
            },
            (orgErr) => {
              return { valid: false, org: '', orgdata: { logo: AppLogo }, err: orgErr };
            }
          );
        }
      }

      this.state.URLs[this.state.entrypointTag] = entryPoint;
      this.setState({
        ssrequest_ids: entryPoint.ssrequest_ids
      });
    }

    if (entryPoint.valid !== true) {
      this.setState({
        loggedIn: false,
        currErr: {
          header: 'Invalid Request',
          msg: 'We could not find a valid Secure Send request. Please follow the link you were sent or contact the sender for more information.'
        },
        formType: 'InvalidRequest',
        logo: AppLogo
      });
      return;
    }

    if (this.state.URLs[this.state.entrypointTag].orgdata.logo !== this.state.logo) {
      this.setState({
        logo: this.state.URLs[this.state.entrypointTag].orgdata.logo
      });
    }

    this.setState({
      disclaimer: this.state.URLs[this.state.entrypointTag].orgdata.disclaimer
    });

    // Check if the user is logged in - if so check that login can access this endpoint.
    this.checkEntryPointUser();
  }

  setStateLoggedInUserInvalid = () => {
    this.setError({
      header: 'Forbidden',
      msg: 'You do not have access to this SecureSend Request',
      log: true,
      logLevel: consts.errorLevels.usererror,
      errAction: 'Login error - Forbidden'
    });

    this.setState({
      loggedIn: true,
      formType: 'loggedInUserInvalid'
    });
  };

  onChange = (e) => {
    e.persist();
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  async isAuthenticated() {
    try {
      //var hasCurrentSession =
      await Auth.currentSession();
      return true;
    } catch {
      return false;
    }
  }

  async debugStuff(event, state) {
    console.log('^^ START DEBUG ^^');
    console.log(state);

    console.log('debugStuff --->');
    console.log('  cognitoUSer: -->');
    var cogUser = state.cognitoUser;
    console.log(cogUser);

    console.log('  Flag 2 -->');
    await Auth.currentAuthenticatedUser().then(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log('err fetching Auth.currentAuthenticatedUser');
        console.log(err);
      }
    );

    console.log('  currentSession -->');
    await Auth.currentSession().then(
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log('err fetching Auth.currentSession');
        console.log(err);
      }
    );
    console.log('^^ END DEBUG ^^');
  }

  doLogout = (logoutMsg, e) => {
    var local_currErr = {
      header: 'You have been logged out.',
      msg: logoutMsg ? logoutMsg : ''
    };

    Auth.signOut().then(
      (res) => {
        this.setState({
          loggedIn: false,
          formType: 'signIn',
          currErr: local_currErr
        });

        // SecureSendLog
        var logLevel = 5;
        var logOpts = secureSendActions.getSecureSendLogInput(this.state.ssrequest_ids[0], this.state.cognitoUser, "Logout", logLevel, {});
        API.graphql(graphqlOperation(mutations.createSecureSendLog, {
          input: logOpts.standardLog
        }));
      },
      (err) => {
        //TODO: Check when this would occur.
        //console.log('Error condition occurred on logout');
        //console.log(err);
        this.setState({
          loggedIn: false,
          formType: 'signIn',
          currErr: local_currErr
        });
      }
    );
  };

  signInCallback = (params) => {
    this.setState(params);
  };

  genericStateCallback = (params) => {
    //console.log('genericStateCallback: ' + JSON.stringify(params));

    if (!params.currErr) {
      params.currErr = null;
    }

    this.setState(params);
  };

  confirmationCodeSuccess = (tag) => {
    //console.log('confirmationCodeSuccess');
    // log the confirmation code success - at this point we're logged in officially..
    var logLevel = consts.errorLevels.normal;  // increase log level as don't need to display this one each time..
    var logOpts = secureSendActions.getSecureSendLogInput(this.state.ssrequest_ids[0], this.state.cognitoUser, "Logged In", logLevel, {});
    API.graphql(graphqlOperation(mutations.createSecureSendLog, {
      input: logOpts.standardLog
    }));



    this.checkEntryPointUser();
  };

  confirmationCodeError = (opts) => {
    /*
    console.log("confirmationCodeError");
    console.log(opts);
    */
    if (opts.exhausted) {
      this.setState({
        currErr: {
          header: 'Confirmation Code Error - Too many attempts',
          msg: opts.msg
        },
        formType: 'signIn'
      });
    } else {
      this.setState({
        currErr: {
          header: 'Confirmation Code Error',
          msg: opts.msg
        }
      });
    }
  };

  authSignClicked = (username) => {
    this.setState({
      loading: true
    });

    username = username.toLowerCase().trim();

    this.setState({
      username: username
    });

    // SecureSendLog
    var logLevel = consts.errorLevels.extended;  // increase log level as don't need to display this one each time..
    var logOpts = secureSendActions.getSecureSendLogInput(this.state.ssrequest_ids[0], username, "Login Initiated", logLevel, {});
    API.graphql(graphqlOperation(mutations.createSecureSendLog, {
      input: logOpts.standardLog
    }));

    Auth.signIn(username).then(
      (res) => {
        //console.log('Setting form state: ' + this.state.formType + ' -> requestConfirmationCode');
        this.setState({
          currErr: null,
          loginPageError: '',
          cognitoUser: res,
          formType: 'requestConfirmationCode',
          loading: false
        });

        // SecureSendLog
        var logLevel = consts.errorLevels.extended;  // increase log level as don't need to display this one each time..
        var logOpts = secureSendActions.getSecureSendLogInput(this.state.ssrequest_ids[0], username, "Confirmation Code Requested", logLevel, {});
        API.graphql(graphqlOperation(mutations.createSecureSendLog, {
          input: logOpts.standardLog
        }));

        return;
      },
      (err) => {
        this.setState({ loading: false });
        this.setError({
          header: 'Error - Forbidden',
          msg: 'That username is not valid for this request. Please try again.',
          err: err,
          log: true,
          logLevel: consts.errorLevels.usererror,
          errAction: 'Login error - Forbidden'
        });

        return;
      }
    );
  };

  render() {
    return (
      <div className="App container">
        <div className="NavBar">
          {this.state.loggedIn && (
            <button
              className="btn btn-info m-1 pull-left invisibleElem"
              onClick={this.debugStuff.bind(this, this.state)}
            >
              Debug
            </button>
          )}
          {!this.state.loggedIn && (
            <button
              className="btn btn-info m-1 pull-left hideElem"
              onClick={this.debugStuff.bind(this, this.state)}
            >
              Debug
            </button>
          )}

          <img src={this.state.logo} className="App-logo" alt="logo" />

          {this.state.loggedIn && (
            <button
              className="btn btn-warning btn-warning-light m-1 pull-right"
              onClick={this.doLogout.bind(this, '')}
            >
              Logout
            </button>
          )}

          <div className="SecureSendHeaderText">
            {/*
            <span className="bluetext">S</span><span className="orangetext">ecure</span><span className="bluetext">S</span><span className="orangetext">end</span>
            <span className="orangetext">S</span><span className="bluetext">ecure</span><span className="orangetext">S</span><span className="bluetext">end</span>
            */}
            <span className="bluetext"> SecureSend </span>
          </div>
        </div>
        <div className="hideElem">
          Request: {pathname}
          <br />
          formType: {this.state.formType}
          <hr />
        </div>
        <div className="NavDivider"></div>
        {this.state.currErr !== null && (
          <div key="currErrMessage" className="alert alert-danger">
            <div className="errorHeader">{this.state.currErr.header}</div>
            <div className="errorMessage" dangerouslySetInnerHTML={{ __html: this.state.currErr.msg }} />
          </div>
        )}
        <div>
          {this.state.formType === 'signIn' && (
            <AuthSignIn
              username={this.state.username}
              loginPageError={this.state.loginPageError}
              authSignClicked={this.authSignClicked}
              orgData={this.state.URLs[this.state.entrypointTag].orgdata}
              pref={this.state.URLs[this.state.entrypointTag].pref}
              loading={this.state.loading}
            />
          )}
          {this.state.formType === 'requestConfirmationCode' && (
            <AuthConfirmationCode
              currState={this.state}
              confirmationCodeSuccess={this.confirmationCodeSuccess}
              confirmationCodeError={this.confirmationCodeError}
            />
          )}
          {this.state.formType === 'loggedInUserValid' && (
            <SecureSendRequestJS
              cognitoUser={this.state.cognitoUser}
              entrypointTag={this.state.entrypointTag}
              secureSendRequestIDs={this.state.ssrequest_ids}
              org={this.state.URLs[this.state.entrypointTag].orgdata.org}
              setError={this.setError}
            />
          )}
          {/*this.state.formType === 'loggedInUserInvalid' && <div>You're in BUT don't have access to this SecureSendRequest</div>*/}
          {this.state.formType === 'validateEntryPoint' && (
            <div>
              Validating request - please wait. . .
              {/*
              <button className="btn btn-primary" onClick={this.validateEntryPoint}>
                Validate
              </button>
              */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default App;
