export const getSecureSendLogInput = function(ssrequest_id, cognitoUser, action, logLevel, payload){
    var currNow = new Date().toISOString();
    var billable = (action.search('SMS') >= 0);
    var billableOpts = null;
    var inputOpts = {
        ssrequest_id: ssrequest_id,
        ts: currNow,
        logLevel: logLevel,
        who: cognitoUser,
        action: action,
        details: payload,
        billable: null,
        targetId: payload.targetId,
        targetLabel: payload.targetLabel,
        createdAt: currNow,
        updatedAt: currNow
    };

    /*
    if (billable){
        inputOpts.billable = {
            medium: 'SMS',
            units: 0,
            payload: ''
        };
    }
    */

    if (cognitoUser.username !== undefined){
        inputOpts.who = cognitoUser.username;
    }

    switch(action){
        case 'File Download':
        case 'File View':
            if (payload.file !== undefined){
                inputOpts.targetId = payload.file.file_uuid;
                inputOpts.targetLabel = payload.file.filename;
            }
            break;
        default:
            break;
    }

    if (billable){
        billableOpts = { ...inputOpts };
    }
    //console.log('getSecureSendLogInput', inputOpts);

    return {
        standardLog: inputOpts,
        billableLog: billableOpts
    }
}


/*
type SecureSendBillable @model @key(fields: ["org", "ts"]) {
	org: String!
	ts: AWSDateTime!
	ssrequest_id: String
	who: String
	targetId: String
	targetLabel: String
	action: String
	medium: String
	units: Int
	recipient: String
	details: String
	createdAt: AWSDateTime,
	updatedAt: AWSDateTime
}

*/